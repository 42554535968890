<template>
  <div class="container">
    <!-- 星级图 -->
    <div class="top">
      <div class="t_left">
        <img v-if="userInfo.GradeType != 0" :src="require('@/assets/newImg/donate_t_star' +
          (userInfo.GradeType - 1) +
          '.png')
          " width="70px" height="63" />
      </div>
      <img :src="require('@/assets/newImg/donate_t_star' + userInfo.GradeType + '.png')
        " width="116px" height="105px" />
      <img :src="require('@/assets/newImg/donate_t_star' +
        (userInfo.GradeType + 1) +
        '.png')
        " width="70px" height="63px" />
    </div>
    <img src="@/assets/newImg/donate_t_line.png" />
    <!-- 价格 -->
    <div class="title">
      <div class="t_txt">${{ collectionInfo.PayAmount }}</div>
      <div>
        {{
          userInfo.TransferType == 'ToolFee'
          ? $t('pay').ToolUsage.toUpperCase()
          : $t('pay').Gratitude.toUpperCase()
        }}
      </div>
    </div>
    <!-- 捐赠规则 -->
    <div class="info">
      <div class="in_top">
        <img src="@/assets/newImg/donate_i_t.png" width="25px" />
        <div class="in_top_txt">
          {{ $t('pay').donationRules.toUpperCase() }}
        </div>
      </div>
      <div class="in_content" v-html="ruleInfo"></div>
      <div class="triangle"></div>
    </div>
    <!-- 按钮 -->
    <div class="btn" @click="toAgree">
      {{ $t('pay').wantDonate.toUpperCase() }}
    </div>
    <!-- 等待审核弹窗 -->
    <van-dialog v-model="showAudit" :showConfirmButton="false">
      <div class="dialog_upLock">
        <div class="di_info">
          <div class="di_i_title">{{ $t('pay').AuditMsg.toUpperCase() }}</div>
          <div class="di_i_btn" @click="$router.push({ name: 'Home' })">
            {{ $t('pay').ReturnHome.toUpperCase() }}
          </div>
        </div>
      </div>
    </van-dialog>
    <!-- 升级锁弹窗 -->
    <van-dialog v-model="showUpLock" :showConfirmButton="false">
      <div class="dialog_upageLock">
        <div class="di_info">
          <div class="di_i_title">{{ $t('pay').UpgradeMsg.toUpperCase() }}</div>
          <div class="di_i_msg" v-text="$t('pay').UpgradeComentMsg1 + (userInfo.GradeType < 7 ? 5 : userInfo.GradeType < 8 ? 6 : userInfo.GradeType
            < 9 ? 7 : userInfo.GradeType < 10 ? 8 : 9) + $t('common').Star + '，' + $t('pay').UpgradeComentMsg2">
          </div>
          <div class="di_i_btn" @click="$router.push({ name: 'Home' })">
            {{ $t('pay').ReturnHome.toUpperCase() }}
          </div>
        </div>
      </div>
    </van-dialog>
    <!-- 分享锁弹窗 -->
    <van-dialog v-model="showShareLock" :showConfirmButton="false">
      <div class="dialog_shareLock">
        <div class="di_info">
          <div class="di_i_title">{{ $t('pay').ShareLockMsg }}</div>
          <div class="di_i_btn" @click="$router.push({ name: 'Home' })">
            {{ $t('pay').ReturnHome.toUpperCase() }}
          </div>
        </div>
      </div>
    </van-dialog>
    <!-- 感恩锁弹窗 -->
    <van-dialog v-model="showGrateLock" :showConfirmButton="false">
      <div class="dialog_grateLock">
        <div class="di_info">
          <div class="di_i_title">
            {{ $t('pay').GratitudeLock.toUpperCase() }}
          </div>
          <div class="di_i_msg">{{ $t('pay').CompleteUnlock }}</div>
          <div class="di_i_txt">{{ $t('pay').GratitudeLockMsg }}</div>
          <div class="di_i_btn" @click="showGrateLock = false">
            {{ $t('pay').ToUnlock.toUpperCase() }}
          </div>
        </div>
      </div>
    </van-dialog>
    <TabBar :active="'Donate'" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import userApi from '@/api/user'
import TabBar from '@/components/tabBarNew'
import { getLang } from '@/utils/validate'
import { Toast } from 'vant'
export default {
  components: {
    TabBar
  },
  data() {
    return {
      showAudit: false, //审核中弹窗
      showUpLock: false, //升级锁
      showShareLock: false, //分享锁弹窗
      showGrateLock: false, //感恩锁弹窗
      userInfo: { GradeType: 1 },
      collectionInfo: {},
      ruleInfo: '',
      canNext: false
    }
  },
  watch: {},
  computed: {
    ...mapGetters(['basicConfig'])
  },
  mounted() {
    this.getAccountBase()
    this.getDonateRules()
    this.getMyCollectionDetail()
  },
  methods: {
    //捐赠规则
    async getDonateRules() {
      const conmentList = await userApi.getContentTitles('DonateRules', {
        lang: getLang()
      })
      if (conmentList.Data.length > 0) {
        const contenInfo = await userApi.getContentDetail({
          lang: getLang(),
          contentId: conmentList.Data[0].Id
        })
        this.ruleInfo = contenInfo.Data.Content
      }
    },
    //获取用户信息
    async getAccountBase() {
      const aBase = await userApi.getAccountBase()
      this.userInfo = aBase.Data
      localStorage.setItem('userInfo', JSON.stringify(aBase.Data))
      //审核中
      if (this.userInfo.OrderStatus == 1) this.showAudit = true
      //升级锁 不符合升级条件
      if (this.userInfo.OrderStatus == 2) this.showUpLock = true
      //分享锁
      if (this.userInfo.OrderStatus == 3) this.showShareLock = true
      //感恩锁
      if (this.userInfo.TransferType.includes('GratefulLock'))
        this.showGrateLock = true
      //十星用户已达最高等级 感恩锁除外
      if (
        !this.userInfo.TransferType.includes('GratefulLock') &&
        this.userInfo.GradeType >= 10
      ) {
        this.$dialog
          .alert({
            confirmButtonText: this.$t('common').comfirm,
            message: this.$t('pay').TopStarLimtMsg
          })
          .then(() => {
            this.$router.push({ name: 'Home' })
          })
      } else this.getCollection()
    },
    //获取收款信息
    async getCollection() {
      const form = {
        TransferType: this.userInfo.TransferType
      }
      const aBase = await userApi.getCollection(form)
      if (aBase.Success) {
        this.canNext = true
        this.collectionInfo = aBase.Data
      }
    },
    // 获取我的收款信息
    async getMyCollectionDetail() {
      const res = await userApi.getMyCollectionDetail()
      const data = res.Data
      if (
        (data.CollectionName == '' ||
          data.CollectionBank == '' ||
          data.CollectionAccount == '') &&
        data.TRC20 == '' &&
        data.UserQRCode.length < 1
      ) {
        this.$dialog
          .alert({
            confirmButtonText: this.$t('common').comfirm,
            message: this.$t('pay').SetPaymentMsg
          })
          .then(() => {
            this.$router.push({ name: 'Payment' })
          })
        return false
      }
    },
    toAgree() {
      if (this.canNext) this.$router.push({ name: 'DonateAgreement' })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  min-height: 100vh;
  width: 100%;
  background-image: url(../../assets/newImg/donate_bg.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-bottom: 80px;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 50px 30px 20px 30px;

  .t_left {
    width: 70px;
  }
}

.title {
  text-align: center;
  font-family: PingFang SC;
  font-weight: 800;
  font-size: 15px;
  color: #ffffff;
  margin: 20px 0 30px 0;

  .t_txt {
    margin-bottom: 10px;
  }
}

.info {
  margin: 0 20px;
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0px 5px 24px 0px rgba(73, 47, 182, 0.75);
  font-family: PingFang SC;
  font-weight: 500;
  font-size: 12px;
  color: #492fb6;
  line-height: 15px;
  min-height: 250px;

  .in_top {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    .in_top_txt {
      margin-left: 15px;
      font-family: PingFang SC;
      font-weight: 800;
      font-size: 15px;
      color: #492fb6;
      text-align: center;
    }
  }

  .in_content {
    width: 100%;
    white-space: normal;
    word-break: break-word;
  }
}

.triangle {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  /* 左边透明 */
  border-right: 10px solid transparent;
  /* 右边透明 */
  border-bottom: 10px solid #fff;
  /* 底边为气泡框背景色 */
  top: -10px;
  /* 位于气泡框顶部 */
  left: 50%;
  /* 居中 */
  transform: translateX(-50%);
  /* 水平居中 */
}

.btn {
  margin: 25px 20px 0px 20px;
  height: 44px;
  background: linear-gradient(0deg, #ff9002 0%, #fcb81d 100%);
  box-shadow: 0px 2px 4px 0px rgba(173, 61, 0, 0.75);
  border-radius: 22px;
  font-family: PingFang SC;
  font-weight: 800;
  font-size: 18px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

::v-deep .van-dialog {
  background-color: transparent;
  width: 320px;
}

.dialog_upLock {
  background-image: url(../../assets/newImg/dialog_warn.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .di_info {
    width: 100%;
    // height: 280px;
    padding-top: 135px;
    padding-bottom: 1px;
    text-align: center;

    .di_i_title {
      padding: 0 20px;
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 24px;
      color: #ff6600;
      line-height: 30px;
    }

    .di_i_btn {
      margin: 50px 15px 15px 15px;
      height: 39px;
      background: linear-gradient(0deg, #ff9002 0%, #fcb81d 100%);
      border-radius: 20px;
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 16px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.dialog_upageLock {
  background-image: url(../../assets/newImg/dialog_uplock.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .di_info {
    width: 100%;
    // height: 280px;
    padding-top: 135px;
    padding-bottom: 1px;
    text-align: center;

    .di_i_title {
      padding: 0 20px;
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 24px;
      color: #ff6600;
      line-height: 30px;
    }

    .di_i_msg {
      margin: 15px;
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 15px;
      color: #333333;
      line-height: 30px;
    }

    .di_i_btn {
      margin: 50px 15px 15px 15px;
      height: 39px;
      background: linear-gradient(0deg, #ff9002 0%, #fcb81d 100%);
      border-radius: 20px;
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 16px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.dialog_shareLock {
  background-image: url(../../assets/newImg/dialog_share.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .di_info {
    width: 100%;
    // height: 360px;
    padding-top: 130px;
    padding-bottom: 1px;
    border-radius: 18px;
    text-align: center;

    .di_i_title {
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 18px;
      color: #ef001e;
      padding: 30px 20px 50px 20px;
    }

    .di_i_msg {
      margin: 15px;
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 15px;
      color: #333333;
    }

    .di_i_txt {
      margin: 15px;
      padding: 10px;
      height: 100px;
      background: #ffffff;
      border-radius: 10px;
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 15px;
      color: #333333;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: left;
    }

    .di_i_btn {
      margin: 15px;
      height: 39px;
      background: linear-gradient(0deg, #ff9002 0%, #fcb81d 100%);
      border-radius: 20px;
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 16px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.dialog_grateLock {
  background-image: url(../../assets/newImg/dialog_grate.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .di_info {
    width: 100%;
    height: 355px;
    padding-top: 125px;
    text-align: center;

    .di_i_title {
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 18px;
      color: #ef001e;
    }

    .di_i_msg {
      margin: 15px;
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 15px;
      color: #333333;
    }

    .di_i_txt {
      margin: 15px;
      padding: 10px;
      height: 100px;
      background: #ffffff;
      border-radius: 10px;
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 15px;
      color: #333333;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: left;
    }

    .di_i_btn {
      margin: 15px;
      height: 39px;
      background: linear-gradient(0deg, #ff9002 0%, #fcb81d 100%);
      border-radius: 20px;
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 16px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
